
import { motion, } from "framer-motion";
import { useState, useEffect, useMemo, useLayoutEffect, useCallback } from "react";
import styled from "styled-components";
import { extent, timeMonths, timeMonth, timeFormat, timeParse, max } from "d3";
import { CardGroup } from "../ScenarioElements/CardGroup";
import * as R from "ramda";
import { sortByDateStr } from "../../utils/data-formatting";
import { atom, useAtom } from "jotai";
import { Months } from "./CalendarMonth";
import { Stack } from "@bedrock-layout/stack";
import { PadBox } from "@bedrock-layout/padbox";
import { clientThemes } from "../../constants";
import { ScenarioGroupOutput } from "../ScenarioGroups/ScenarioGroupOutput";


export const startDatesAtom = atom([])
const start = new Date("2022-01-01")
const parseTimeYMD = timeParse("%Y-%m-%d");






export default function InteractiveCalendar({ currentGroup }) {

    const elements = useMemo(() => {

        return currentGroup?.elements?.items
            .sort((a, b) => sortByDateStr(a, b, "end_date", parseTimeYMD))
            .sort((a, b) => sortByDateStr(a, b, "start_date", parseTimeYMD)) || []

    }, [currentGroup]);


    const LOOKAHEAD_MONTHS = 2;
    const VISIBLE_MONTHS = 12;

    const currentDate = new Date()
    const end = timeMonth.offset(currentDate, LOOKAHEAD_MONTHS);
    const months = timeMonths(start, end)


    const byStart = R.groupBy(o => [o?.start_date, o?.end_date])
    const groupedElements = useMemo(() => byStart(elements), [elements]);
    const groupKeys = useMemo(() => {
        return Object.keys(groupedElements)
    }, [groupedElements])




    const [startDates, setStartDates] = useAtom(startDatesAtom);

    useEffect(() => {
        const starts = groupKeys
            .map(str => str.split(",")[0])
            .map(d => parseTimeYMD(d))
        setStartDates(starts);

    }, [groupKeys])



    const dateInfo = useMemo(() => {
        let maxDate = max(startDates);
        if (!maxDate) {
            maxDate = timeMonth(end);
        }

        return {
            maxDate: maxDate,
            maxMonth: timeMonth(maxDate)
        }
    }, [startDates])





    const startIdx = useMemo(() => {
        const monthStrings = months.map(o => timeFormat("%b %Y")(o));

        const dateRange = extent(startDates)
        const dateRangeString = dateRange
            .map(date => timeMonth(date))
            .map(date => timeFormat("%b %Y")(date));


        const maxMonth = dateRangeString.slice(-1)[0];
        let idx = monthStrings.indexOf(maxMonth)

        if (idx < 0) {
            idx = months.length - LOOKAHEAD_MONTHS;
        }
        return idx

    }, [startDates, months])



    const [idxs, setIdxs] = useState(null);

    const visibleMonths = useMemo(() => {
        if (idxs === null || idxs.length < 2) {
            return []
        }
        // return months.slice(idxs[0], idxs[1])
        return months.slice()
    }, [idxs])



    useEffect(() => {
        if (currentGroup) {
            setIdxs([startIdx, startIdx + VISIBLE_MONTHS])
        }
        return () => setIdxs(null);
    }, [currentGroup, startIdx])


    const tabs = ["Overview", "Detail"]
    const [activeTab, setActiveTab] = useState(tabs[0])

    const renderContent = useCallback(() => {
        switch (activeTab) {
            case "Overview":
                return (<CardStack groupKeys={groupKeys} groupedElements={groupedElements} currentGroup={currentGroup} />)
            case "Detail":
                return (<ScenarioGroupOutput />)
            default:
                throw new Error("No tab defined.")
        }
    }, [groupKeys, groupedElements, currentGroup, activeTab])




    return (
        <PadBox padding={["lg", "xxl"]}>
            <Stack gutter="lg">
                <div style={{ fontSize: ".8rem" }}>
                    <p>

                        <strong>Partnership Projection Tool: </strong>This tool allows you to create calendar scenarios & projections for T-Mobile Tuesday offers. Begin creating an offer by left-clicking on a date. Right-click or press ESC to cancel. Projections will appear on the <strong>Overview</strong> tab, and can also be exported from the <strong>Detail</strong> tab.
                    </p>

                </div>
                <div style={{display:"list-item", fontSize: ".8rem", listStyleType: "disc"}}>
                <ul>
  <li>For partners whose save & redemption volumes have changed significantly over time (e.g. Redbox, Auntie Anne’s), projections may be inflated or deflated due to historical performance.</li>
    <ul>
    <li>e.g. Redbox averaged 88K redemptions from 2017-2019, but more recently has averaged ~45K redemptions since 2020. Therefore, the higher historical rate from 2017-2019 may slightly inflate projections.</li>
  </ul>
 </ul> 
  </div>
  <div style={{display:"list-item", fontSize: ".8rem", listStyleType: "disc", marginTop:'-15px'}}>
                <ul>
  <li>If a partner is not available in the drop-down selection (and you believe this partner has previously ran a promotion) please notify joe.bringley@tmsw.com</li>
  </ul> 
  </div>



                <Months dateInfo={dateInfo} months={visibleMonths} />
                <TabGroup tabs={tabs} activeTab={activeTab} setActive={setActiveTab} />
                <hr/>
                {renderContent()}
            </Stack>
        </PadBox>
    )
}

const TabGroup = ({ tabs, activeTab, setActive }) => {
    return (
        <ButtonGroup>
            {
                tabs.map(name => (
                    <Tab
                        key={name}
                        active={+(activeTab === name)}
                        onClick={() => setActive(name)}
                    >{name}</Tab>
                ))
            }
        </ButtonGroup>
    )
}

const CardStack = ({ groupKeys, groupedElements, currentGroup }) => {
    if (!groupKeys.length) {
        return (
            <motion.div style={{
                fontSize: "2rem", display: "flex",
                color: clientThemes.primary,
                fontWeight: 100,
                justifyContent: "center"

            }}>
                Click calendar to add entry.
            </motion.div>
        )
    }

    return (
        <PadBox padding={["xl", "sm"]}>

            <Stack gutter="xxl">
                {groupKeys.map(k => {
                    const elementsK = groupedElements[k];
                    return <CardGroup key={k} elements={elementsK} currentGroup={currentGroup} />
                })
                }
            </Stack>
        </PadBox>
    )
}



const Tab = styled(motion.button)`
  font-size: 1rem;
  cursor: pointer;
  padding: .2rem 2rem;
  opacity: 0.6;
  background: white;
  border: 0;
  outline: 0;
  ${({ active }) =>
        active &&
        `
    border-bottom: 2px solid black;
    opacity: 1;
    transition: opacity .5s;

  `}

  &:focus {
    outline: none;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;