
import { motion, AnimatePresence } from "framer-motion";
import { Spinner } from '../../components/shared/Spinners';
import { activeText, clientThemes, inactiveText, STICKYBAR_HEIGHT, TOPBAR_HEIGHT } from '../../constants';
import { useState } from 'react';
import { FaPlusCircle, FaTrash } from 'react-icons/fa';
import { useGroup, useDeleteGroup } from '../../utils/api-utils';
import { ScenarioGroupNewModal } from './ScenarioGroupNew';
import { ScenarioGroupEditModal } from "./ScenarioGroupEdit";
import { IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FloatingMenuIcon from '../../components/FloatingIcon/FloatingMenuIcon';
import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/inline';
import { groupIDAtom } from './ScenarioGroupsView';
import { useAtom } from 'jotai';
import { useModalReducer } from "../Modals/Modal";



export function CreateNewGroup({ size = 20 }) {
  const [modalOpenNew, setModalOpenNew] = useState(false);

  const close = () => setModalOpenNew(false);
  const open = () => setModalOpenNew(true);
  const handleNewModal = () => (modalOpenNew ? close() : open())

  return (
    <>

      <MotionIcon
        className="leading-none"
        whileHover={{ color: "#000", scale: 1.2 }}
        onClick={() => {
          handleNewModal()
        }}
        style={{ color: "#999" }}
        tooltip="Create New Group">
        <FaPlusCircle size={size} />
      </MotionIcon>
      {modalOpenNew && <ScenarioGroupNewModal handleModal={handleNewModal} />}
    </>
  )
}


// const initialModalState = {
//   open: false,
// }

// function modalReducer(state, action) {
//   switch (action.type) {
//     case "open":
//       return { open: true }
//     case "close":
//       return { open: false }
//     default:
//       throw new Error();
//   }
// }

export function StickyBanner() {

  const [groupID, _] = useAtom(groupIDAtom);

  const {
    mutate: deleteGroup,
  } = useDeleteGroup("/partners");

  const navigate = useNavigate();

  const [modalState, handleModal] = useModalReducer()


  const { data = {}, isLoading, isFetching } = useGroup(groupID);


  const version = data?.version;
  const name = data?.name;
  const isEditing = data?.editing;


  return (
    <>

      <AnimatePresence
        initial={false}
        onExitComplete={() => null}
      >
        {modalState.open && <ScenarioGroupEditModal modalState={modalState} handleModal={handleModal} />}
      </AnimatePresence>
      <PadBox
        padding={["md", "md"]}
        className="flex flex-col items-center shadow-md"
        style={{
          position: "sticky",
          // backgroundColor: "rgba(235, 235, 235, .9)",
          backgroundColor: clientThemes.primary,
          // border: "5px solid red",
          zIndex: 999,
          marginTop: "4rem",
          top: 0,
          left: 0,
          width: "100%",
          justifySelf: "center",
        }}
      >

        <Inline
          gutter="lg"
          align="center"
          stretch={1}
          style={{
            position: "relative",
            // minHeight: "3rem",
            height: STICKYBAR_HEIGHT,
            // border: "5px solid green",
            width: "100%"
          }}>
          <FloatingMenuIcon />
          <StatusLabel
            isFetching={isFetching}
            isLoading={isLoading}
            isEditing={isEditing}
            label={name}
            handleClick={() => {
              handleModal()
            }} />
          <MotionIcon
            whileHover={{ color: "#fc033d", scale: 1.3 }}
            onClick={() => {
              if (window.confirm("Are you sure you want to delete this scenario group?")) {
                console.log("Deleting groupID", groupID)
                deleteGroup({ id: groupID, version: version })
              }
            }}
            tooltip="Delete">
            <FaTrash size={20} color="#fff"/>
          </MotionIcon>
        </Inline>

      </PadBox>

    </>
  )
}


export function MotionIcon({ tooltip, children, placement, ...rest }) {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        {...rest}
       
      >
        <Tooltip title={tooltip}
          placement={placement || "bottom"}
          style={{
            outline: "none",
            padding: 5,
          }}>
          <IconButton>
            {children}
          </IconButton>
        </Tooltip>
      </motion.div>
    </AnimatePresence>
  )
}



export function StatusLabel({ isFetching, isLoading, isEditing, label, handleClick }) {

  return (

    <motion.div
      className="flex flex-col items-center rounded-md select-none"
      style={{
        // border: "1px dotted green",
        zIndex: 10,
        backgroundColor: "rgba(240, 240, 240, 0)"
      }}
      animate={{
        color: isEditing === false ?
          activeText :
          inactiveText
      }}
    >
      <motion.div className="tracking-tighter uppercase leading-none"
        whileHover={{
          scale: 1.2,
          backgroundColor: "rgba(240, 240, 240, 1)",
          color: clientThemes.primary,
          fontWeight: 700,
          // transition: { duration: . }
        }}
        onClick={handleClick}
        style={{
          minHeight: "1rem",
          borderRadius: "5px",
          padding: ".3rem .7rem",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          display: "flex",
          justifyContent: "center",
          fontSize: "1.2rem",
          fontWeight: 100,
          // color: "#fff",

        }}>
        {label}
      </motion.div>
    </motion.div>

  )
}

export function ForecastStatus({ isFetching }) {
  return (
    <motion.div
      className="text-sm font-bold flex block items-center"
      style={{
        minHeight: "20px",
        minwidth: "100px",
        justifySelf: "center",
        color: activeText,
      }}>
      <motion.span className="font-thin mr-2">Forecast Status: </motion.span>
      <motion.span>
        {

          isFetching ?
            <Spinner width="20" height="20" /> :
            "Ready"
        }
      </motion.span>
    </motion.div>
  )
}