
import styled from "styled-components"
import { motion } from "framer-motion";
import { useAtom } from "jotai";
import { useState, useEffect, } from "react";
import { timeFormat, timeTuesday, timeDays,  } from "d3";
import { startDatesAtom } from "./InteractiveCalendar";
import { clientThemes } from "../../constants";
import { Day, DayBase } from "./CalendarDay";

export const WeekBase = styled(motion.div)`
  display: flex;
`

export const Week = ({ start }) => {
    const [startDates, _] = useAtom(startDatesAtom);
    const [active, setActive] = useState(false)
    const end = timeTuesday.offset(start, 1);
    const days = timeDays(start, end);
    const formatTime = timeFormat("%Y-%m-%d")


    useEffect(() => {
        const cond = startDates.map(formatTime).includes(formatTime(start))
        setActive(cond)
    }, [startDates])

    const variants = {
        selected: { background: clientThemes.primary + "1A", color: "rgba(255, 255, 255)" },
        deselected: { background: "rgba(255, 255, 255, 0)", color: "rgba(0, 0, 0)" },
    }

    return (
        <WeekBase style={{ 
            // borderBottom: "2px solid white"
        }}>
            {days.map((o, idx) => {
                return <Day key={o} day={o} format="%d" />
            })}
        </WeekBase>
    )
}



const DayLabel = ({ date }) => {
    return (
        <DayBase
            style={{
                fontWeight: 600,
                fontSize: ".8rem",
                border: "none",
            }}
        >
            {timeFormat("%a")(date)}
        </DayBase>
    )
    
}


export const WeeksHeader = ({ start }) => {
    const end = timeTuesday.offset(start, 1);
    const days = timeDays(start, end);

    return (
        <WeekBase>
            {days.map(o => {
                return (<DayLabel
                    key={o}
                    date={o}
                />)
            })}
        </WeekBase>
    )
}




