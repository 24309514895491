
import { motion } from "framer-motion";
import { useCallback, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import Backdrop from "./Backdrop";
import "./styles.css";


const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};



export const withModal = (Component) => ({ handleModal, ...props }) => {
  return (
    <Backdrop onClick={handleModal}>

      <motion.div
        className="modal select-none"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <Component {...props} handleModal={handleModal} />
      </motion.div>
    </Backdrop>
  )
}


export const ModalWrapper = ({ children, isOpen, handleModal }) => {

  const navigate = useNavigate();

  useEffect(() => {
    const closeOnEscapeKey = e => e.key === "Escape" 
      ? handleModal() 
      : null;

    document.body.addEventListener("keydown", closeOnEscapeKey);

    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey)
    }
  }, [handleModal])

  if (!isOpen) return null;

  return (
    <Backdrop onClick={handleModal}>
      <motion.div
        className="modal select-none"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {children}
      </motion.div>
    </Backdrop>
  )
}




export function useModalReducer(isOpen = false) {

  const initialModalState = {
    open: isOpen,
  }

  function modalReducer(state, action) {
    switch (action.type) {
      case "open":
        return { ...state, open: true }
      case "close":
        return { ...state, open: false }
      default:
        throw new Error();
    }
  }


  const [modalState, dispatch] = useReducer(modalReducer, initialModalState)

  const handleModal = useCallback(async () => {
    modalState.open === true
      ? dispatch({ type: "close" })
      : dispatch({ type: "open" })

  }, [modalState])


  return [modalState, handleModal]


}

