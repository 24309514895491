import companyLogo from "../../assets/img/tmsLogo2022.svg";
import { motion, LayoutGroup, useViewportScroll, useScroll } from "framer-motion";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
import { HeaderLeft, Right } from "../shared/TailwindElements";
import UserMenu from "../Tailwind/partials/header/UserMenu";
import { buttonText, clientThemes, primaryBg, tmsThemes, TOPBAR_HEIGHT } from "../../constants";
import { useEffect, useState } from 'react';


export const NavMenu = (props) => {
  return (
    <div
      className={`flex items-center justify-around rounded-full w-48v lg:w-38v whitespace-nowrap`}
      style={{ 
        backgroundColor: "rgba(200, 200, 200, 0.2)", 
        zIndex: "0",
       }}
      {...props}>
    </div>
  )
}



const Selection = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 0;
  z-index: -1;
`;


const Topbar = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  padding: 0 2rem;
  width: 100vw;
  background: rgba(255, 255, 255, .5);
  height: ${TOPBAR_HEIGHT};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  z-index: 9999;
`



export default function TabBar({ tabs, testid }) {
  const location = useLocation();
  const splitPath = location.pathname.split("/")
  const groupID = splitPath.length === 3 ? splitPath[2] : undefined

  const { scrollY } = useScroll();

  const [hidden, setHidden] = useState(false)

  const SCROLL_CUTOFF_Y = 10;

  const update = () => {
    if (scrollY?.current < scrollY?.prev) {
      setHidden(false)
    } else if (scrollY?.current > SCROLL_CUTOFF_Y && scrollY?.current > scrollY?.prev) {
      setHidden(true)
    }
  }

  useEffect(() => {
    return scrollY.onChange(() => update())
  })


  const scrollVariants = {
    hidden: { opacity: 0, y: -25},
    visible: { opacity: 1, y: 0}
  }


  const variants = {
    active: {
      color: buttonText,
    },
    inactive: {
      color: clientThemes.primary, 
    },
  };

  const ICON_SIZE = 20;
  const ICON_COLOR = tmsThemes.lightblue;

  return (
    <Topbar
      variants={scrollVariants}
      animate={hidden ? "hidden" : "visible"}
      transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.6 }}
    >
      <HeaderLeft>
        <NavLink to="/">
          <img
            className="w-12"
            src={companyLogo}
            alt="logo" />
        </NavLink>
      </HeaderLeft>
      <LayoutGroup>
        <NavMenu>
          {tabs.map(({ name, to }, i) => (
            <NavLink
              key={i}
              to={
                groupID && (to === "/calendar")
                  ? `${to}/${groupID}`
                  : `${to}`
              }
              replace={true}
              style={{ textDecoration: "none" }}
            >
              <motion.div
                className={`whitespace-nowrap select-none relative
                      uppercase
                      mx-4
                      px-12 
                      my-1 py-1
                      tracking-tighter
                      ${location.pathname.startsWith(to) ? "font-bold" : ""}
                      text-lg cursor-pointer`}
                     
                name="Tab"
                variants={variants}
                animate={
                  location.pathname.startsWith(to) ? "active" : "inactive"
                }
                whileHover={{
                  scale: location.pathname.startsWith(to) ? undefined : 1.1,
                }}
              >
                {name}
                {location.pathname.startsWith(to) && (
                  <Selection
                    name="Background"
                    layout
                    initial={false}
                    animate={{ backgroundColor: ICON_COLOR }}
                    transition={{ duration: .1 }}
                  />
                )}
              </motion.div>
            </NavLink>
          ))}
        </NavMenu>
      </LayoutGroup>
      <Right>
        <UserMenu iconSize={ICON_SIZE} color={ICON_COLOR} />
      </Right>
    </Topbar>
  );
}