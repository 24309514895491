import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { PageSpinner } from "../../components/shared/Spinners";
import { useDeleteElement } from "../../utils/api-utils";
import { sortByDate } from "../../utils/data-formatting";
import { extent } from "d3"
import { tidy, summarize, sum, groupBy, mutate, mutateWithSummary } from '@tidyjs/tidy'
import { useMemo, version } from "react";
import { ElementsChart, LoadingOverlay, PlaceholderOverlay } from "./ElementsChart";
import { Card } from "./Card";
import { fetchingAtom, useFetchForecast } from "../InteractiveCalendar/useFetchForecast";
import { useAtom } from "jotai";
import { Stack } from "@bedrock-layout/stack";
import { FaPlay } from "react-icons/fa";
import { clientThemes } from "../../constants";
import { vmax } from "../ScenarioGroups/ScenarioGroupOutput";





const Header = styled.div`
    font-weight: 100;
    font-size: 1.1rem;
    position: absolute;
    top: -1.8rem;
    left: -.8rem;
    // letter-spacing: -0.05em;
`



export function CardGroup({ elements, currentGroup }) {


  const globalElements = useMemo(() => {
    return currentGroup?.elements?.items || []
  }, [currentGroup])

  const {
    mutate: deleteElement,
    isLoading: isDeleting,
    isError: isDeleteError,
  } = useDeleteElement()

  const [fetching, _] = useAtom(fetchingAtom);
  const { handleFetch, handleResetForecast } = useFetchForecast(globalElements);


  const resultsGrouped = useMemo(() => {
    const results = currentGroup?.result[0]?.tableData || [];

    return tidy(
      results,
      mutateWithSummary({
        partnerside_mean_volume: items => items.map(d => vmax(d, 'partnerside_mean_volume', 'redemptions_mean_volume')),
      }),
      groupBy(['id', 'partner'], [
        summarize({
          uv: sum('prediction'),
          redemptions: sum('redemptions_mean_volume'),
          partnerside: sum('partnerside_mean_volume'),
          saves: sum('claims_mean_volume')
        }),
      ])
    )
  }, [currentGroup])




  const resultsDomain = useMemo(() => {
    const redemptions = extent(resultsGrouped.map(o => o.redemptions))
    const partnerside = extent(resultsGrouped.map(o => o.partnerside))
    const saves = extent(resultsGrouped.map(o => o.saves))
    return {
      redemptions,
      partnerside,
      saves
    }
  }, [resultsGrouped])


  const handleDelete = (id, version, groupID) => {
    if (window.confirm("Are you sure you want to delete this offer?")) {
      deleteElement({ id, version, groupID })
    }
  }



  return (
    <AnimatePresence>
      {!isDeleting && <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
      <Stack gutter="sm" style={{ position: "relative" }}>
        <Header>
          {`${elements[0]?.start_date}  -  ${elements[0]?.end_date}`}
        </Header>
        <>
          {elements
            .sort((a, b) => sortByDate(a, b, "start_date"))
            .map((item) => {
              const itemData = resultsGrouped.filter(o => o?.id === item?.id)

              return (
                <Card
                  key={item?.id}
                  item={item}
                  handleDelete={handleDelete}
                  handleFetch={handleFetch}
                  handleReset={handleResetForecast}>
                  <AnimatePresence>
                    <LoadingOverlay key={`loading-overlay-${item?.id}`} fetching={fetching} handleFetch={handleFetch} data={itemData} />
                    {itemData.length > 0 && <ElementsChart key={`elements-chart-${item?.id}`} data={itemData} domain={resultsDomain}/>}
                  </AnimatePresence>
                </Card>
              );
            })}
        </>
      </Stack>
      </motion.div>}
    </AnimatePresence>
  )
}

