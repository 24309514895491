import useFormState from "../../hooks/useFormState";
import ScenarioGroupForm from "./ScenarioGroupForm";
import { PageSpinner } from "../../components/shared/Spinners";
import { useNavigate, useParams, } from 'react-router-dom';
import { useGroup, useUpdateGroup } from '../../utils/api-utils';
import { motion } from "framer-motion";
import { withModal } from '../Modals/Modal';
import { tabURLs } from "../../constants";

export default function ScenarioGroupEdit({ handleModal }) {

  const url = tabURLs['partners'];

  const { groupID } = useParams()

  const { data, isLoadingData, error } = useGroup(groupID)

  const formState = useFormState(data);

  const navigate = useNavigate()
  const { updateFn, isUpdating, updateError } = useUpdateGroup()

  async function handleSubmit() {
    const formData = { ...formState.state };
    const { id, name, version } = formData;
    const inputData = {
      id: id,
      name: name,
      expectedVersion: version
    }
    updateFn(inputData)
    await handleModal()

  }

  function handleCancel() {
    handleModal()
  }

  if (isLoadingData) {
    return <ScenarioLoadingScreen/>
  }



  return (
    <>
      <ScenarioGroupForm formState={formState} handleSubmit={handleSubmit} edit={true} url={url} handleCancel={handleCancel}/>
    </>
  )
}


export const ScenarioGroupEditModal = withModal(ScenarioGroupEdit);

export function ScenarioLoadingScreen() {
  return (
    <motion.div className="m-4 p-16 text-black text-lg flex flex-col items-start bg-hot-pink rounded-md">
      <PageSpinner />
    </motion.div>
  )
}