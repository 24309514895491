import { Amplify, Auth, Hub } from 'aws-amplify';
import { useEffect, useRef, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Routes, Route, useLocation, Outlet } from "react-router-dom";
import { atom, useAtom } from "jotai";


import MetaData from "./contexts/MetaData";
import { AppWrapper } from "./components/shared/TailwindElements";
import axios from "axios"
import { rAudienceURL, rURL, apiKey } from './api-constants';
import WarmupContext from "./contexts/Warmup";
import config from './config';
import AudienceView from './pages/Audience/AudienceView';
import ScenarioGroupsView from './pages/ScenarioGroups/ScenarioGroupsView';
import ScenarioGroupEdit from './pages/ScenarioGroups/ScenarioGroupEdit';
import ScenarioGroupNew from './pages/ScenarioGroups/ScenarioGroupNew';
import TabBar from './components/Navigation/TabBar';
import { PartnersPageOutlet, ScenarioGroupsOutlet } from './routes/PartnersPageRoutes';
import { StickyBanner } from './pages/ScenarioGroups/StickyBanner';
import { Stack } from '@bedrock-layout/stack';
import { useScroll } from 'framer-motion';


Amplify.configure({
  aws_project_region: "us-east-1",
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: config.oauth.domain,
      scope: config.oauth.scope,
      redirectSignIn: config.oauth.redirectSignIn,
      redirectSignOut: config.oauth.redirectSignOut,
      responseType: config.oauth.responseType
    }
  },
  API: {
    endpoints: [
      {
        name: config.apiGateway.name,
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      }
    ]
  },
  aws_appsync_graphqlEndpoint: config.graphql.URL,
  aws_appsync_region: config.graphql.REGION,
  aws_appsync_authenticationType: config.graphql.AUTHENTICATION_TYPE,
  aws_cloud_logic_custom: config.aws_cloud_logic_custom
});


const queryClient = new QueryClient();
export const minDateAtom = atom("");
export const maxDateAtom = atom("");

const RouteLayout = () => {

  const { pathname } = useLocation()

  return (
    <>
      <TabBar
        tabs={[
          { name: "Partners", to: "/partners" },
          { name: "Audience", to: "/audience" },
        ]}
      />

      {pathname.startsWith("/partner") && <StickyBanner />}

      <Stack gutter="sm">
        <Outlet />
      </Stack>
    </>
  )
}


export const audienceReadyAtom = atom(false);
export const partnerReadyAtom = atom(false);
export const userAtom = atom(null);

function App() {
  const [audienceReady, setAudienceReady] = useAtom(audienceReadyAtom);
  const [partnerReady, setPartnerReady] = useAtom(partnerReadyAtom);
  const [user, setUser] = useAtom(userAtom);
  const [minDate, setMinDate] = useAtom(minDateAtom)
  const [maxDate, setMaxDate] = useAtom(maxDateAtom)

  const handleUser = (userData) => setUser(userData?.username || "")



  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then(userData => handleUser(userData));
          break;
        case 'signOut':
          setUser(null);
          console.log("Hub Sign out")
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
        default: 
          throw new Error(`Case for Hub event '${event}' not defined`);

      }
    });

    getUser().then(userData => handleUser(userData));
    return () => {console.log("Hub.listen cleanup")}
  }, []);

  function getUser() {
    const user = Auth.currentAuthenticatedUser();
    console.log("Checking for user:", user)
    return user
      .then(userData => userData)
      .catch(() => {
        console.log('Not signed in')
        Auth.federatedSignIn();
      }
      );
  }



  useEffect(() => {
    if (user !== null) {
      if (!audienceReady) {
        warmup(rAudienceURL, setAudienceReady, "audience")
      }
    }
  }, [user, audienceReady])

  useEffect(() => {
    if (user !== null) {
      if (!partnerReady) {
        warmup(rURL, setPartnerReady, "partner", setMinDate, setMaxDate)
      }
    }
  }, [user, partnerReady])





  return (
    // <TestApp/>
    <QueryClientProvider client={queryClient}>
        <WarmupContext.Provider value={{ audienceReady, partnerReady }}>
          <MetaData>
            <AppWrapper>

              <Routes>
                <Route path="/" element={<RouteLayout />}>
                  <Route index element={<ScenarioGroupsView />} />
                  <Route path="partners" element={<PartnersPageOutlet />}>
                    <Route index element={<ScenarioGroupsView />} />
                    <Route path="new" element={<ScenarioGroupNew />} />
                    <Route path=":groupID" element={<ScenarioGroupsOutlet />}>
                      <Route index element={<ScenarioGroupsView />} />
                      <Route path="edit" element={<ScenarioGroupEdit />} />
                      <Route path=":elementID/edit" element={<ScenarioGroupsView /> } />
                      <Route path="new" element={<ScenarioGroupsView/> } />
                    </Route>
                  </Route>

                  <Route path="audience" element={<AudienceView />} />
                  <Route path="*" element={<NoMatchPage />} />
                </Route>
              </Routes>

            </AppWrapper>
          </MetaData>
        </WarmupContext.Provider>
      <ReactQueryDevtools initialIsOpen={true} />
    </QueryClientProvider>
  );
}




function NoMatchPage() {
  return (
    <div style={{ margin: "2rem" }}>
      <p>Page doesn't exist. Click the back button.</p>
    </div>
  )
}


async function warmup(url, setReady, _apiName = "", setMinDate = null, setMaxDate = null) {
  const apiName = _apiName.toUpperCase()
  console.log(`Running warmup: ${apiName}`)

  // const apiData = apiName === "audience" ? 
  const warmupData = apiName === "PARTNER" ? { req: "local" } : { body: { req: "local" } }

  await axios({
    method: 'post',
    url: url,
    // data: { body: { req: "local" } },
    // data: warmupData,
    data: { req: "local" },
    headers: {
      'X-Api-Key': apiKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    }
  })
    .then((resp) => {
      console.log(`Global Warmup Response (${apiName}):`, resp.data)
      if (resp.data[0]?.id || resp.data?.id) {
        setReady(true)

        if (apiName === "PARTNER") {
          setMinDate(resp.data.dateRange.min_date)
          setMaxDate(resp.data.dateRange.max_date)
        }
        console.log(`Warmup Successful: ${apiName}`)
      }
    })
    .catch(error => {
      console.log("Error fetching R Data: ", error)
      setReady(false)
    })

}

const TestApp = (props) => {

  const ref = useRef(null)
  const { scrollXProgress, scrollX, scrollY, scrollYProgress } = useScroll({ container: ref })
 
  scrollYProgress.onChange(latest => {
      console.log("latest", latest)
  })

  // if (months.length === 0) {
  //     return <></>
  // }


  return (
      <div
          ref={ref}
          style={{ 
              // overflowX: "scroll",
              // overflow: "auto",
              overflowY: "scroll",
              zIndex: 9999,
              // maxWidth: "500px",
              maxHeight: "200px",
              border: "5px solid red",
              display: "flex",
              flexDirection: "column",
          }}
      >
          <div style={{ height: "100px", minWidth: "300px", background: "blue", margin: "1px"}}>1</div>
          <div style={{ height: "100px", minWidth: "300px", background: "blue", margin: "1px"}}>1</div>
          <div style={{ height: "100px", minWidth: "300px", background: "blue", margin: "1px"}}>1</div>
          <div style={{ height: "100px", minWidth: "300px", background: "blue", margin: "1px"}}>1</div>
          <div style={{ height: "100px", minWidth: "300px", background: "blue", margin: "1px"}}>1</div>
          <div style={{ height: "100px", minWidth: "300px", background: "blue", margin: "1px"}}>1</div>
          <div style={{ height: "100px", minWidth: "300px", background: "blue", margin: "1px"}}>1</div>
          <div style={{ height: "100px", minWidth: "300px", background: "blue", margin: "1px"}}>1</div>
          <div style={{ height: "100px", minWidth: "300px", background: "blue", margin: "1px"}}>1</div>
          <div style={{ height: "100px", minWidth: "300px", background: "blue", margin: "1px"}}>1</div>
          <div style={{ height: "100px", minWidth: "300px", background: "blue", margin: "1px"}}>1</div>
          <div style={{ height: "100px", minWidth: "300px", background: "blue", margin: "1px"}}>1</div>

      
      </div>
  )
}


export default App;
