

import styled from "styled-components"
import { motion, AnimatePresence, useScroll } from "framer-motion";
// import { motion, AnimatePresence, useScroll } from "framer-motion";
import { timeMonth, timeFormat, timeTuesdays, timeTuesday, timeDays, timeDay } from "d3";
import { Week, WeeksHeader } from "./CalendarWeek";
import { Columns } from "@bedrock-layout/columns";
import { useEffect, useRef, forwardRef, useLayoutEffect, useCallback } from "react";
import { flushSync } from "react-dom";
import { atom, useAtom } from "jotai";
import { groupAtom } from "../ScenarioGroups/ScenarioGroupsView";
import { Tooltip } from "@mui/material";
import { useHoverTimeout } from "../../hooks/useTimeout";
import { clientThemes } from "../../constants";


const formatMonYear = timeFormat("%b %Y");

const MonthScroll = forwardRef((props, ref) => {
    return (
        <MonthColumns ref={ref} {...props}></MonthColumns>
    )
})

const MonthColumns = styled.div`
    // border: 5px dotted black;
    & *:hover {
        cursor: pointer;
    }
    overflow-x: scroll;
    display: flex;
    & > * + * {
        margin: 0rem 2rem;
        flex: 1;
    }

    ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: rgba(100, 100, 100, .1);
    -webkit-border-radius: 1ex;
    }

    ::-webkit-scrollbar-thumb {
    background: ${clientThemes.primary};
    -webkit-border-radius: 1ex;
    }

    ::-webkit-scrollbar-corner {
    background: #fff3;
    }
`

const MonthContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    // border-right: 1px dotted black;
    min-width: 30vw;
    background: ${props => props.background || "none"};
`

const WeeksContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    border: 2px solid red;
`


export const scrollOffsetAtom = atom(0)

export const Month = ({ start, isLatest }) => {
    const ref = useRef(null);

    const [group] = useAtom(groupAtom)
    const scrollToMonth = useCallback(() => {
        if (isLatest && ref.current) {
            ref.current.scrollIntoView(false)
        }
    }, [ref.current, isLatest])

    useLayoutEffect(() => {
        scrollToMonth()
    }, [isLatest, group])


    const end = timeMonth.offset(start, 1)
    const weeks = timeTuesdays(start, end);

    const variants = {
        hidden: { opacity: 0 },
        enter: { opacity: 1 },
    }

    return (
        <MonthContainer
            id={start}
            layout
            variants={variants}
            exit={{ opacity: 0 }}
            ref={ref}
        >
            <h1>{formatMonYear(start)}</h1>
            <WeeksHeader start={weeks[0]} />
            <>
                {weeks.map(o => {
                    return <Week key={o} start={o} />
                })}
            </>
        </MonthContainer>
    )
}



export function areDatesEq($date1, $date2, format = "%Y-%m-%d") {
    const formatTime = timeFormat(format)
    const date1 = formatTime($date1)
    const date2 = formatTime($date2)

    return date1 === date2

}

export const Months = ({ months, dateInfo }) => {

    return (

            <MonthColumns>
                <AnimatePresence>
                    {months.length && months.map((o, i) => {
                        return (
                            <Month
                                key={o}
                                start={o}
                                isLatest={areDatesEq(o, dateInfo.maxMonth)}
                            />
                        )
                    })
                    }
                </AnimatePresence>

            </MonthColumns>
    )
}
