const redirect = process.env?.NODE_ENV === "development" ?
  "http://localhost:3000/" :
  "https://qa.tmtprojection.com/"

export default {
	apiGateway: {
        name: 'tmob-forecast-qa-audience_estimator',
		REGION: 'us-east-1',
		URL: 'https://czze8f9fk6.execute-api.us-east-1.amazonaws.com/qa/tmob-forecast-qa-audience_estimator'
	},
	cognito: {
		REGION: 'us-east-1',
		USER_POOL_ID: 'us-east-1_onl6l6Ok0',
		APP_CLIENT_ID: '10mr7kb8diqn0frlj4b2rrcfec',
		IDENTITY_POOL_ID: 'us-east-1:0dbd6ffd-e17a-42e6-aae0-e82fe66f1acd'
	},
	graphql: {
		URL: 'https://a5y4rz5tj5djjhc7fhs77e34re.appsync-api.us-east-1.amazonaws.com/graphql',
		REGION: 'us-east-1',
		AUTHENTICATION_TYPE: 'AMAZON_COGNITO_USER_POOLS'
	},
	oauth: {
		domain: 'tmtprojection-qa.auth.us-east-1.amazoncognito.com',
		scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin'],
		redirectSignIn: redirect,
		redirectSignOut: redirect,
		responseType: 'token' // or 'token', note that REFRESH token will only be generated when the responseType is code
	},
	aws_cloud_logic_custom: [
    {
        "name": "AdminQueries",
        "endpoint": "https://hfioodw5ve.execute-api.us-east-1.amazonaws.com/test",
        "region": "us-east-1"
    }
]
}