import { AnimatePresence, AnimateSharedLayout, motion, useCycle } from "framer-motion";
import { defaultText, panelBorder, primaryBg, sidebarBg } from "../../constants";
import styled from "styled-components";



export const AppWrapper = ({ children }) => {

  return (
    <div 
      // className="w-screen h-screen max-h-screen relative" 
      style={{
      fontFamily: "Roboto",
      backgroundColor: primaryBg,
    }}>{children}</div>
  );
};

export const Header = ({ children }) => {
  return (
    <header className="sticky top-0 border-b border-opacity-50 border-gray-200 z-30"
      style={{
        backgroundColor: primaryBg,
      }}
    >
      <div className="px-4 py-2 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-12 mb-px">
          {children}
        </div>
      </div>
    </header>
  );
};


export const HeaderLeft = ({ children }) => {
  return <div className="flex">{children}</div>;
}

export const HeaderRight = ({ children }) => {
  return <div className="flex items-center mr-2">{children}</div>;
}

export const Right = styled.div`
  display: flex;
  gap: 1rem;
  padding: .5rem;
  justify-content: space-evenly;
`


export const HeaderButton = () => {
  return (
    <button
      className="text-gray-500 hover:text-gray-600 lg:hidden"
      aria-controls="sidebar"
    >
      <span className="sr-only">Open sidebar</span>
      <svg
        className="w-6 h-6 fill-current"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="4" y="5" width="16" height="2" />
        <rect x="4" y="11" width="16" height="2" />
        <rect x="4" y="17" width="16" height="2" />
      </svg>
    </button>
  );
}

export const Sidebar = ({ children }) => {
  return (
    <AnimateSharedLayout>
      <motion.div
        animate={{ width: "auto" }}
        initial={{ width: 0 }}
        exit={{ width: 0 }}
        className="flex flex-col sticky select-none"
        style={{
          backgroundColor: sidebarBg,
        }}
        layout
      >
        <motion.div 
        className={`
        flex flex-col flex-1 w-32 py-6 text-sm
        md:w-52 md:pt-12 md:text-md
  
        `}
        style={{
          color: defaultText,
        }}
        layout>
          {children}
        </motion.div>
      </motion.div>
    </AnimateSharedLayout>
  );
}
export const VerticalButton = (props) => {
  return (
    <motion.button
      className="w-2 h-48 ml-4 rounded-full bg-white"
      autoFocus={false}
      style={{ outline: "none "}}
      whileHover={{
        scaleX: 1.5,
        scaleY: 1.5
      }}
      {...props}
    ></motion.button>
  );
}

export const ContentPanel = ({ children }) => {
  return (
    <div className="flex mx-auto w-screen overflow-hidden h-screen shadow-lg">
      <div className="flex flex-1 w-md tracking-tight">
        <div className="flex-1 pt-4 pb-12 px-4 overflow-auto"
          style={{
            color: defaultText
          }}
        >{children}</div>
      </div>
    </div>
  );
}


export const MainPanel = ({ children, toggleOpen, styles, ...props }) => {
  return(
    <div
        name="MainPanelContent"
        className="flex flex-1 w-md"
        style={{ ...styles }}
      >
        {toggleOpen && 
        <div className="flex items-center w-12 group" onClick={toggleOpen}>
          <VerticalButton />
        </div>
        }
        <div className="flex-1 py-8 px-8 overflow-auto"
          style={{
            color: defaultText
          }}
        >
          {children}

        </div>
      </div>
  )
}

export const Panel = ({ sidebar, content }) => {
  const [open, toggleOpen] = useCycle(true, false)
  return (
    <div className="flex mx-auto w-screen overflow-hidden h-screen shadow-lg">
      <AnimatePresence initial={false}>{open && sidebar}</AnimatePresence>
      <MainPanel 
        toggleOpen={toggleOpen}
        styles={{
        borderLeft: `2px solid ${panelBorder}`
      }}
      >{content}</MainPanel>
    </div>
  );
}
