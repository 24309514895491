
import styled from "styled-components"
import { AnimatePresence, motion } from "framer-motion";
import { atom, useAtom } from "jotai";
import { useState, useEffect, useMemo, useContext } from "react";
import { timeFormat, timeDays, timeDay, timeParse } from "d3";
import { clientThemes, tmsThemes } from "../../constants";
import { AiFillCheckCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { groupAtom } from "../ScenarioGroups/ScenarioGroupsView";
import { useReducerAtom } from "jotai/utils";
import { ModalContext } from "../ScenarioGroups/ElementModalProvider";
import { areDatesEq } from "./CalendarMonth";


// Day

export const DayBase = styled(motion.div)`
    flex: 1;
    font-size: .9rem;
    border-radius: 5px;
    border-left: 2px solid rgba(255, 255, 255, 1);
    border-top: 2px solid rgba(255, 255, 255, 1);
    padding: .2rem .6rem;
    width: 100%;
    text-align: center;
    z-index: 2;
`


const rangeDefault = {
    allowSelection: false,
}


export const rangeAtom = atom(rangeDefault);

export function rangeReducer(state, action) {
    const { type, payload } = action
    switch (type) {
        case "reset":
            return rangeDefault;
        case "begin_selection":
            return { allowSelection: true, ...payload }
        case "end_selection":
            return { ...state, allowSelection: false }
        case "update_selection":
            return { ...state, ...payload }
        default:
            throw new Error();
    }
}



function asDate(date, format = "%Y-%m-%d") {
    if (date instanceof Date) return date;
    if (typeof date === "string") {
        return timeParse(format)(date);
    }
}



function dateInRange($day, $rangeStart, $rangeEnd) {
    const [day, rangeStart, rangeEnd] = [asDate($day), asDate($rangeStart), asDate($rangeEnd)];


    const dayRange = timeDays(rangeStart, timeDay.offset(rangeEnd, 1))
        .map(timeFormat("%Y-%m-%d")) || [];

    return dayRange.includes(timeFormatYMD(day));
}



const timeFormatYMD = timeFormat("%Y-%m-%d")





export const Day = ({ day, format, ...props }) => {
    const dayNum = timeFormat(format)(day);
    const [currentGroup, _] = useAtom(groupAtom);
    const [range, dispatch] = useReducerAtom(rangeAtom, rangeReducer);
    const [hovered, setHovered] = useState(false);
    const { handleModalNew: handleModal } = useContext(ModalContext)

    const selected = useMemo(() => {
        if (!range.start || !range.end) {
            return false
        }
        return dateInRange(day, range.start, range.end)
    }, [range])



    useEffect(() => {
        dispatch({ type: "reset" })
    }, [])


    const ranges = useMemo(() => {
        return currentGroup?.elements?.items
            .map(o => ({
                start_date: o?.start_date,
                end_date: o?.end_date,
                partner: o?.partner,
                day: day,
                dateInRange: dateInRange(day, o?.start_date, o?.end_date)
            }))
            .filter(o => o.dateInRange === true)

    }, [currentGroup])



    const inRange = useMemo(() => {
        return ranges
            .map(o => o.dateInRange)
            .some(x => x);

    }, [ranges])


    const isStart = useMemo(() => {
        return ranges
            .map(o => o?.start_date === timeFormatYMD(day))
            .some(x => x);

    }, [ranges])

    const isEnd = useMemo(() => {
        return ranges
            .map(o => o?.end_date === timeFormatYMD(day))
            .some(x => x);

    }, [ranges])

    const isRangeEnd = useMemo(() => {
        if(!range?.end) return false;
        return areDatesEq(range.end, day)
    }, [range])



    const navigate = useNavigate()


    const handleHover = (date) => {
        if (!range.start || !range.initial) return;
        if (range?.allowSelection === false) return;


        if (date < range.initial) {
            dispatch({
                type: "update_selection",
                payload: { start: date }
            })
        }

        if (date > range.initial) {
            dispatch({
                type: "update_selection",
                payload: { end: date }
            })
        }

        if (date === range.initial) {
            dispatch({
                type: "update_selection",
                payload: { start: date, end: date }
            })
        }
    }

    

    const handleClick = (date) => {
        if (range.allowSelection) return;

        dispatch({
            type: "begin_selection",
            payload: { start: date, end: date, initial: date }
        })
    }

    const handleConfirm = async (e) => {
            dispatch({ type: "end_selection" })
            handleModal()
            navigate(`/partners/${currentGroup?.id}/new`, { replace: true })
        
    }


    useEffect(() => {
        const closeOnEscapeKey = e => e.key === "Escape" 
            ?  dispatch({ type: "reset" }) 
            : null;
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey)
        }
    }, [])


    const variants = {
        selected: { background: clientThemes.primary + "33" },
        default: { background: "rgba(255, 255, 255, 0)" },
        hovered: { background: clientThemes.primary + "CC" }
    }

    return (
        <div style={{
            position: "relative",
            flex: 1,
        }}>
            <AnimatePresence>
                {isRangeEnd && (
                    <Confirm key={day} onClick={() => {
                        handleConfirm()
                    }
                    } />
                )}
            </AnimatePresence>
            <DayBase
                {...props}
                variants={variants}
                initial="default"
                animate={selected ? "selected" : "default"}
                whileHover="hovered"
                onMouseEnter={() => handleHover(day)}
                onClick={() => handleClick(day)}
                onContextMenu={(e) => {
                    e.preventDefault()
                    dispatch({ type: "reset" })
                }}
            >
                {dayNum}
                {inRange && <Overlay isStart={isStart} isEnd={isEnd} />}
            </DayBase>
        </div>
    )
}


const Overlay = styled.div`
    background: rgba(100, 100, 100, .2);
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-left: ${props => props.isStart === true ? `5px solid ${clientThemes.secondary}` : "1px solid #fff"};
    border-right: ${props => props.isEnd === true ? `5px solid ${clientThemes.primary}` : "1px solid #fff"};
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;


`
const ConfirmBase = styled(motion.span)`
    position: absolute;
    top: 10px;
    right: -10px;
    z-index: 1;
`

const Confirm = ({ onClick }) => {
    const [hovered, setHovered] = useState(false);
    const variants = {
        hovered: { scale: 1.6 },
        initial: { scale: 1},
    }

    useEffect(() => {
        return () => setHovered(false);
    }, [])


    
    return (
        <ConfirmBase
            initial="initial"
            variants={variants}
            onClick={onClick}
            onMouseEnter={ e => {
                setHovered(true)
            }}
            onMouseLeave={ e => {
                setHovered(false)
            }}
            animate={ hovered ? "hovered" : "initial" }
        >
            <AiFillCheckCircle size={20} color={tmsThemes.green} />
        </ConfirmBase>
    )
}