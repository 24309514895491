import styled from "styled-components"
import { motion } from "framer-motion";
import { FaEdit, FaEraser, FaPlay, FaTrash } from "react-icons/fa";
import { Stack } from "@bedrock-layout/stack";
import { Inline } from "@bedrock-layout/inline";
import { clientThemes } from "../../constants";
import { Split } from "@bedrock-layout/split";
import { Column, Columns } from "@bedrock-layout/columns";
import { PadBox } from "@bedrock-layout/padbox";
import { useNavigate } from "react-router-dom";
import { MotionIcon } from "../ScenarioGroups/StickyBanner";
import ElementModalProvider, { ModalContext } from "../ScenarioGroups/ElementModalProvider";
import { ReactPortal } from "../../portals/ReactPortal";
import { useContext } from "react";


const CardColumns = styled(Columns)`
    font-size: .8rem;
`

const CardPadding = styled(PadBox)`
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 5px;
    * {
        cursor: pointer;
    }
`

const MotionCard = motion(CardPadding);


const AttributeLabel = styled.div`
    border-radius: 5px;
    background: ${props => props.background || "rgba(95, 95, 95, .5)"};
    padding: .1rem .4rem;
    white-space: nowrap;
    color: white;
    font-size: .7rem;
`

const PartnerLabel = styled.div`
    white-space: nowrap;
    font-weight: 300;
    font-size: 1.2rem;
`
const MotionPartnerLabel = motion(PartnerLabel)

const StyledColumn = styled(Column)`
    overflow: hidden;
    transition: overflow 1s;
    
    &:hover {
        overflow: visible;
    }
`

export function Card({ item, children, handleDelete, handleFetch, handleReset }) {
    const navigate = useNavigate()


    const { handleModalEdit: handleModal } = useContext(ModalContext)

    return (
        <MotionCard
            padding={["lg", "lg"]}
            style={{ background: "rgba(255, 255, 255, 0)"  }}
            whileHover={{ background:"rgba(230, 230, 230, .5)"  }}
            whileTap={{ background:"rgba(230, 230, 230, 1)"  }}
            onDoubleClick={(e) => {
                e.stopPropagation();
                handleModal()
                navigate(`/partners/${item?.groupID}/${item?.id}/edit`);
            }}
        >
            <CardColumns columns={8}
                switchAt="600px"
                style={{ justifyContent: "center", alignItems: "center", }} >
                <StyledColumn span={3}>
                    <Stack gutter="sm">
                        <Inline gutter="lg" align="center">
                            <MotionPartnerLabel
                                style={{ minWidth: "2ch" }}
                            >
                                {item?.new_partner_flag === true
                                    ? item?.new_partner
                                    : item?.partner[0]}
                            </MotionPartnerLabel>
                            <MotionIcon
                                whileHover={{ scale: 1.5 }}
                                onClick={e => {
                                    e.stopPropagation()
                                    handleFetch()
                                }}
                                tooltip="Run forecast"
                                placement="top"
                            >
                                <FaPlay size={16} color={clientThemes.quinary} />
                            </MotionIcon>
                            <MotionIcon
                                whileHover={{ scale: 1.5 }}
                                tooltip="Clear forecast"
                                placement="top"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleReset()
                                }}
                            >
                                <FaEraser size={18} color="grey" />
                            </MotionIcon>
                            <MotionIcon
                                whileHover={{ scale: 1.5 }}
                                tooltip="Delete offer"
                                placement="top"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete(item?.id, item?.version, item?.groupID)
                                }}
                            >
                                <FaTrash
                                    size={16}
                                    color="red" />
                            </MotionIcon>

                        </Inline>
                        <Stack gutter="sm" style={{ width: "fit-content" }}>
                            <hr />
                            <Inline gutter="md">
                                <AttributeLabel background="rgba(0, 0, 0, .5)">{item?.offer_construct}</AttributeLabel>
                                <AttributeLabel>{item?.category}</AttributeLabel>
                                <AttributeLabel>{item?.subcategory}</AttributeLabel>
                            </Inline>
                        </Stack>
                    </Stack>
                </StyledColumn>
                <Column span={5} style={{
                    minWidth: "300px",
                    width: "100%",
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    {children}
                </Column>
            </CardColumns>
        </MotionCard>
    )
}