import { Auth } from "aws-amplify";
import axios from "axios";
import { atom, useAtom } from "jotai";
import { useReducerAtom } from "jotai/utils";
import { useCallback, useEffect, useState } from "react";
import { apiKey, rURL } from "../../api-constants";
import { maxDateAtom, minDateAtom, partnerReadyAtom } from "../../App";
import { useUpdateGroup } from "../../utils/api-utils";
import { groupAtom } from "../ScenarioGroups/ScenarioGroupsView";

export const resultsAtom = atom([]);
const fetchingDefault = { isFetching: false, status: "idle" };
export const fetchingAtom = atom(fetchingDefault);

function fetchingReducer(state, action) {
    switch (action.type) {
        case "fetch_reset":
            return { ...fetchingDefault }
        case "fetch_start":
            return { isFetching: true, status: "fetching" }
        case "fetch_success":
            return { isFetching: false, status: "success" }
        case "fetch_fail":
            return { isFetching: false, status: "error" }
        default:
            throw new Error(`Unknown type "${action.type}" passed to fetchingReducer`);
    }
}

export function useResetForecast() {

    const [currentGroup] = useAtom(groupAtom);
    const { updateFn: updateGroup, isUpdating, updateError } = useUpdateGroup()

    const handleResetForecast = useCallback(() => {
        const { id, version } = currentGroup;

        const inputData = {
            id: id,
            editing: false,
            result: [],
            expectedVersion: version
        }

        updateGroup(inputData)

    }, [currentGroup])

    return { handleResetForecast }
}

export function useFetchForecast(elementsData) {

    const [fetching, dispatch] = useReducerAtom(fetchingAtom, fetchingReducer);
    const [results, setResults] = useState([]);
    const [elements, setElements] = useState([]);
    const [currentGroup] = useAtom(groupAtom);
    const [partnerReady] = useAtom(partnerReadyAtom);

    const [minDate] = useAtom(minDateAtom);
    const [maxDate] = useAtom(maxDateAtom);

    useEffect(() => {
        setElements(elementsData)
    }, [elementsData])

    useEffect(() => {
        if (!results || results.length < 1) return
        console.log("Updating group")
        handleSaveForecast()
    }, [results])

    useEffect(() => {
        return () => {
            setResults([])
            dispatch({ type: "fetch_reset" })
        }

    }, [currentGroup])

    const { updateFn: updateGroup, isUpdating, updateError } = useUpdateGroup()


    const checkIfDatesValid = useCallback((scenarioData) => {
        const validity = scenarioData.map((el) => {

            // convert strings to date class for comparison
            const startDateDate = new Date(el.start_date)
            const minDateDate = new Date(minDate)
            const endDateDate = new Date(el.end_date)
            const maxDateDate = new Date(maxDate)

            // calculate truth values of min and max
            const truthMin = startDateDate >= minDateDate
            const truthMax = endDateDate <= maxDateDate

            const truth = truthMin && truthMax
            return (truth)
        })

        // return true or false if all dates are valid
        return (validity.every(x => x === true))


    }, [minDate, maxDate])




    const handleSaveForecast = useCallback(() => {
        const { id, version } = currentGroup;

        if (!results || results.length < 1) {
            console.log("Error. Results missing.")
            return;
        }

        const resultsKeys = Object.keys(results)

        const inputData = {
            id: id,
            editing: false,
            result: results,
            expectedVersion: version
        }

        updateGroup(inputData)

    }, [currentGroup, results])



    const { handleResetForecast } = useResetForecast();


    async function fetchRData(scenarios, checkDates) {

        if (!partnerReady) {
            window.alert("Partner API is loading. Please try refreshing page.")
            return
        }

        dispatch({ type: "fetch_start" })

        const datesAreValid = checkDates(scenarios)

        if (datesAreValid === false) {
            window.alert("Invalid selection: Date range is too far into the future or too far in the past.")
            dispatch({ type: "fetch_reset" })
            handleResetForecast()
            return
        }

        const axiosData = {
            req: {
                postBody: scenarios
            }
        }

        axios({
            method: 'post',
            url: rURL,
            // data: axiosData,
            data: { req: scenarios },
            headers: {
                'X-Api-Key': apiKey,
                'Content-Type': 'application/json',
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            }
        })
            .then((resp) => {
                console.log("Partner resp.data", resp.data)
                const temp = resp.data.tableData.map(o => {
                    const obj = { ...o }
                    delete obj.start_date
                    delete obj.end_date
                    return obj
                })
                resp.data.tableData = temp;
                setResults(resp.data)
            })
            .catch(error => {
                console.log("Error fetching R Data: ", error)
                dispatch({ type: "fetch_fail"})
            })
            .finally(() => {
                dispatch({ type: "fetch_success" })
            })
    }

    const handleFetch = useCallback(async () => {
        if (fetching.isFetching) {
            console.log("Fetch already in progress. Exit.")
            return;
        }
        if (elements.length === 0) {
            console.log("No scenarios provided.")
            return;
        }
        fetchRData(elements, checkIfDatesValid)
    }, [elements, fetching, checkIfDatesValid])

    return { results, fetching, handleFetch, handleResetForecast }


}

