
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, LabelList, Bar, ResponsiveContainer } from "recharts"
import PropTypes from "prop-types";
import { clientThemes } from "../../constants";
import { useEffect, useState, useMemo } from "react";
import { GridLoader } from "react-spinners";
import { motion, AnimatePresence } from "framer-motion";
import { IconButton } from "../../components/shared/Buttons";
import { FaPlay } from "react-icons/fa";
import { Inline } from "@bedrock-layout/inline";

import { format, max } from "d3";
import styled from "styled-components";

const placeholderData = [{
  id: "placeholder",
  saves: 0,
  redemptions: 0,
  partnerside: 0,
  uv: 0,
  partner: ""
}]

const formatSI = format(".2s")
const formatT = format(",")

const CustomLabel = styled.div``

export function LoadingOverlay({ data, fetching, handleFetch }) {

  const { isFetching, status } = fetching;

  const variants = {
    show: { opacity: 1 },
    hide: { opacity: 0 }
  }



  if (data.length > 0) {
    return null
  }


  return (
    <>
      <AnimatePresence>
        <motion.div
          style={{
            // flex: 1,
            width: "100%",
            zIndex: 300,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "rgba(255, 255, 255, 1)"
          }}
          initial="hide"
          animate="show"
          exit={{ opacity: 0 }}
          // transition={{ duration: .1 }}
          variants={variants}
        >
          {data.length === 0 && status !== "fetching" ?
            (<motion.div
              variants={variants}
              initial="hide"
              animate="show"
              exit={{ opacity: 0 }}
              gutter="md"
              onClick={(e) => {
                e.stopPropagation()
                handleFetch()
              }}
            >
              Click <span><FaPlay style={{ display: "inline-block" }} color={clientThemes.quinary} /></span> to run forecast.
            </motion.div>
            )
            : status === "fetching"
              ? (
                <motion.div initial="hide" animate="show" variants={variants} exit={{ opacity: 0 }}>
                  <GridLoader size={10} color={clientThemes.secondary} />
                </motion.div>)

              : null

          }
        </motion.div>
      </AnimatePresence>
    </>
  )
}


const MotionInline = motion(Inline)

export function PlaceholderOverlay({ data, fetching, handleFetch }) {

  const variants = {
    show: { opacity: 1 },
    hide: { opacity: 0 }
  }

  return (
    <>

    </>

  )
}


export function ElementsChart({ data, domain }) {

  const [chartData, setChartData] = useState([]);

  const variants = {
    show: { opacity: 1 },
    hide: { opacity: 0 }
  }


  useEffect(() => {
    if (data.length === 0) {
      setChartData(placeholderData)
      return;
    }
    setChartData(data)
  }, [data])

  const chartDomain = useMemo(() => {
    if(domain.saves.length && domain.redemptions.length ) {
      return [0, max([domain.saves[1], domain.redemptions[1]])]
    }
  }, [domain])



  return (
    <motion.div
      initial="hide"
      animate="show"
      exit={{ opacity: 0 }}
      transition={{ duration: .3 }}
      variants={variants}
      style={{ height: "100%", width: "100%" }}
    >
      <ResponsiveContainer>

        <BarChart
          data={chartData}
          layout="vertical"
          margin={{
            top: 0,
            right: 0,
            left: 100,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" xAxisId="top" hide domain={chartDomain}/>
          <YAxis
            type="category"
            dataKey="partner"
            fontSize=".7rem"

          />
          <Tooltip 
          formatter={d => formatT(d)}
          wrapperStyle={{ zIndex: 9999}}
          />
          <Legend
            align="right"
            verticalAlign="middle"
            layout="vertical"
            wrapperStyle={{ fontSize: ".7rem", paddingLeft: "3rem" }}
            
          />
          <Bar xAxisId="top" dataKey="saves" fill={clientThemes.secondary} isAnimationActive={false} name="Saves">
            <LabelList
              dataKey="saves"
              position="right"
              style={{ fill: "black", fontSize: ".8rem" }}
              formatter={d => formatSI(d)}
            />
          </Bar>
          <Bar xAxisId="top" dataKey="redemptions" fill={clientThemes.primary} isAnimationActive={false} name="Redemptions">
            <LabelList
              dataKey="redemptions"
              position="right"
              style={{ fill: "black", fontSize: ".8rem" }}
              formatter={d => formatSI(d)}
            />
          </Bar>
          <Bar xAxisId="top" dataKey="partnerside" fill={clientThemes.quaternary} isAnimationActive={false} name="Partner Redemptions">
            <LabelList
              dataKey="partnerside"
              position="right"
              style={{ fill: "black", fontSize: ".8rem" }}
              formatter={d => formatSI(d)}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </motion.div>


  )
}


ElementsChart.propTypes = {
  data: PropTypes.array
}

